import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CardBooking from "./cardBooking";
import { minWidth, width } from "@mui/system";
require("dayjs/locale/th");
dayjs.locale("th");

const columns = [
  { id: "sender", label: "ผู้ส่ง", align: "center", width: "240px" },
  { id: "sampleName", label: "ชื่อตัวอย่าง", align: "center", width: "160px" },
  {
    id: "dateTimeStart",
    label: "วัน - เวลา ที่ส่ง",
    align: "center",
    width: "140px",
  },
  { id: "place", label: "ต้นทาง - ปลายทาง", align: "center", width: "240px" },
  { id: "status", label: "สถานะ", align: "center", width: "140px" },
];

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "calc(100% - 30px)",
  },

  partOfAllCard: {
    width: "100%",
    height: "calc(100% - 100px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    overflow: "hidden",
    overflowY: "auto",
    paddingTop: "20px",
    ["&::-webkit-scrollbar"]: {
      width: "10px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    ["&::-webkit-scrollbar-thumb"]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "3px",
    },
  },
  TextNoBooking: {
    fontSize: "30px",
    textAlign: "center",
  },
  Grid: {
    width: "95%",
  },
  TableRow: {
    ["&:hover"]: {
      cursor: "pointer",
    },
  },
}));

const TextHeader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: theme.palette.text["secondary"],
}));

const CustomTableHead = ({ columns }) => (
  <TableHead
    sx={{
      "& .MuiTableCell-root:first-of-type": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "& .MuiTableCell-root:last-of-type": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    }}
  >
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "none",
          borderTop: "none",
        },
      }}
    >
      {columns.map((column) => (
        <TableCell
          key={column.id}
          style={{
            width: column.width,
            background: "#f4f6f8",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TextHeader>{column.label}</TextHeader>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const CustomTableFooter = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => (
  <tfoot>
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "none",
          borderTop: "none",
        },
      }}
    >
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        colSpan={9}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        slotProps={{
          select: {
            "aria-label": "rows per page",
          },
          actions: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        style={{
          position: "sticky",
          bottom: 0,
          background: "white",
          zIndex: 1,
        }}
      />
    </TableRow>
  </tfoot>
);

const DeliverySampleShuttleCar = (props) => {
  const classes = useStyles();
  const [listBookingNotSuccess, setListBookingNotSuccess] = useState([]);
  const [listBookingIsSuccess, setListBookingIsSuccess] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            (props.showHistory === 0
              ? listBookingNotSuccess
              : listBookingIsSuccess
            ).length
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let listBooking = props.booking;
    if (listBooking != null && listBooking.length !== 0) {
      const listA = [];
      const listB = [];
      listBooking.map((booking) => {
        if (booking.deliveryStatus === "ได้รับสินค้าเรียบร้อย") {
          listA.push(booking);
        } else {
          listB.push(booking);
        }
      });
      setListBookingIsSuccess(listA);
      setListBookingNotSuccess(listB);
    } else {
      setListBookingIsSuccess([]);
      setListBookingNotSuccess([]);
    }
    setPage(0);
  }, [props.booking, props.deliverySampleStatus]);

  return isMobile ? (
    <div className={`${classes.DivCoverReturn}`}>
      <CustomTableFooter
        count={
          props.showHistory === 0
            ? listBookingNotSuccess.length
            : listBookingIsSuccess.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {props.showHistory === 0 ? (
        listBookingNotSuccess && listBookingNotSuccess.length > 0 ? (
          <TableBody
            style={{
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {(rowsPerPage > 0
              ? listBookingNotSuccess.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : listBookingNotSuccess
            ).map((booking, index) => (
              <CardBooking
                key={index}
                booking={booking}
                typeOfBooking={props.typeOfBooking}
                onClickCardHandler={props.onClickCardHandler}
              />
            ))}
            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={3} aria-hidden />
              </tr>
            )}
          </TableBody>
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "24px" }}>ไม่มีรายการ</Typography>
          </Grid>
        )
      ) : props.showHistory === 1 ? (
        listBookingIsSuccess && listBookingIsSuccess.length > 0 ? (
          <TableBody
            style={{
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {(rowsPerPage > 0
              ? listBookingIsSuccess.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : listBookingIsSuccess
            ).map((booking, index) => (
              <CardBooking
                key={index}
                booking={booking}
                typeOfBooking={props.typeOfBooking}
                onClickCardHandler={props.onClickCardHandler}
              />
            ))}
            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={3} aria-hidden />
              </tr>
            )}
          </TableBody>
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "24px" }}>ไม่มีรายการ</Typography>
          </Grid>
        )
      ) : null}
    </div>
  ) : (
    <div className={`${classes.DivCoverReturn}`}>
      {props.showHistory === 0 &&
        (listBookingNotSuccess && listBookingNotSuccess.length > 0 ? (
          <TableContainer
            style={{
              // height: '550px',
              height: "100%",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <CustomTableHead columns={columns} />
              <TableBody>
                {(rowsPerPage > 0
                  ? listBookingNotSuccess
                      // .toReversed()
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : listBookingNotSuccess
                ).map((booking, index) => (
                  <CardBooking
                    key={index}
                    booking={booking}
                    typeOfBooking={props.typeOfBooking}
                    onClickCardHandler={props.onClickCardHandler}
                  />
                ))}
                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} aria-hidden />
                  </tr>
                )}
              </TableBody>
              <CustomTableFooter
                count={listBookingNotSuccess.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography style={{ textAlign: "center", fontSize: "24px" }}>
              ไม่มีรายการ
            </Typography>
          </Grid>
        ))}
      {props.showHistory === 1 &&
        (listBookingIsSuccess && listBookingIsSuccess.length > 0 ? (
          <TableContainer
            style={{
              // height: '550px',
              height: "100%",
              overflow: "auto",
            }}
          >
            <Table stickyHeader>
              <CustomTableHead columns={columns} />
              <TableBody>
                {(rowsPerPage > 0
                  ? listBookingIsSuccess
                      // .toReversed()
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : listBookingIsSuccess
                ).map((booking, index) => (
                  <CardBooking
                    key={index}
                    booking={booking}
                    typeOfBooking={props.typeOfBooking}
                    onClickCardHandler={props.onClickCardHandler}
                  />
                ))}
                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} aria-hidden />
                  </tr>
                )}
              </TableBody>
              <CustomTableFooter
                count={listBookingIsSuccess.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography style={{ textAlign: "center", fontSize: "24px" }}>
              ไม่มีรายการ
            </Typography>
          </Grid>
        ))}
    </div>
  );
};

export default DeliverySampleShuttleCar;
