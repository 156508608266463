import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";
import Container from "@mui/material/Container";
import { SnackbarProvider, useSnackbar } from "notistack";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { Grid, Typography, Divider, Chip } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { Title } from "@material-ui/icons";
import {
  Paper,
  Button,
  Avatar,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";

import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  postUpdateDeliveryStatus,
  getDeliverySampleshuttleByIdUser,
  postDeleteDeliverySampleshuttle,
  postEditDeliverySampleshuttle,
} from "../../../../../../actions/deliverySampleshuttle";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../../src/utils/googleMapAssets";
import { getSitebyName } from "../../../../../../actions/site.js";
import SiteService from "../../../../../../../src/services/site.service.js";
import { getLocationDriverById } from "../../../../../../actions/site.js";
import UserServices from "../../../../../../../src/services/user.service.js";
import FoodMenuModal from "./showPicture/index";

import DeleteModal from "../../../../shared/deleteModal/index.js";
import { name } from "dayjs/locale/th";
import GoogleApi from "./showMap/index.js";

require("dayjs/locale/th");
dayjs.locale("th");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const DivCoverReturn = styled("div")({
  height: "fit-content",
  display: "Grid",
  justifyContent: "center",
  // marginTop: "20px",
  width: "100%",
  borderRadius: "10px",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  // width: "100%",
  maxWidth: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "20px",

  // margin: "0px 50px 50px 50px",
  // boxShadow: theme.shadows[3],
  position: "relative",
  // padding: "0 20px",
  "& .BackButton": {
    width: "fit-content",
    color: "black",
    position: "absolute",
    top: "13px",
    // left: "0",
    // padding: "0 25px",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "9px",
      // padding: "0 10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // padding: "0 15px",
  },
}));
const PaperStyledTop = styled(Paper)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "20px",
  // margin: "0px 50px 0px 50px",

  position: "relative",
  // padding: "0 20px",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // padding: "0 15px",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  position: "absolute",
  top: "13px",
  left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));
const ViewButtonMap = styled(Button)(({ theme }) => ({
  border: "1.75px solid #00bb77",
  borderRadius: "5px",
  color: "black",
  width: "100%",
  padding: "10px",
  fontWeight: "bolder",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  ["&:hover"]: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
}));
const ViewButton = styled(Button)({
  border: "1.75px solid #3D9946",
  borderRadius: "5px",
  color: "black",
  width: "100%",
  padding: "10px",
  fontWeight: "bolder",
  backgroundColor: "#3D9946",
  color: "white",
  ["&:hover"]: {
    backgroundColor: "#3D9946",
    color: "white",
  },
});

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: "150px",
  height: "50px",
  color: "white",
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "fit-Content",
  },
}));

const GridContainer = styled(Grid)({
  width: "100%",
  height: "100%",
  marginLeft: "5px",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "100%",
  width: "300px",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: "#637381",
  fontWeight: "600",
}));
const TextFrom = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#212b36",
  fontWeight: "600",
  marginLeft: "5px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  // backgroundColor: "#DDDDDD",
  borderStyle: "dashed",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 2)",
}));

const GridNavButton = styled(Grid)(({ theme }) => ({
  "& .DisapproveButton": {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .ApproveButton": {
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "10px",
  },
}));

const GridButton = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Mumu = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  margin: "10px 0",
  fontWeight: "bolder",
}));
const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  margin: "10px 0",
  fontWeight: "bolder",
  color: "black",
}));

//ตัวอย่าง

const CardSampleDetail = (props) => {
  const [openDeleteBooking, setOpenDeleteBooking] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { result } = useSelector((state) => state.deliverySampleShuttleCar);
  const [deliverySampleStatus, setDeliverySampleStatus] = useState("all");
  const Bookings = result[deliverySampleStatus];
  const [booking, setBooking] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isDriver, setDriver] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenFoodMenu, setIsOpenFoodMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditList, setIsEditList] = useState(true);
  const [isNewList, setIsNewList] = useState(false);
  const [allSite, setAllSite] = useState([]);

  useEffect(async () => {
    if (Bookings) {
      console.log("data found");

      console.log(props.location.state.idBooking);

      const bookingData = Bookings.find(
        (book) =>
          book.idDeliverySampleShuttle === props.location.state.idBooking
      );
      await setBooking(bookingData);
      console.log(bookingData);
      console.log(booking);
    } else {
      const data = await dispatch(
        getDeliverySampleshuttleByIdUser(currentUser.idUser)
      );
      console.log("data not found");

      const bookingData = data.find(
        (book) =>
          book.idDeliverySampleShuttle === props.location.state.idBooking
      );
      await setBooking(bookingData);
    }
    SiteService.getAllSite().then((res) => {
      if (res.data) {
        setAllSite(res.data);
      }
    });
  }, []);

  useEffect(async () => {
    if (booking) {
      if (booking.idDriver) {
        console.log(booking.idDriver);
        const data = await UserServices.getUserProfile(booking.idDriver)
          .then((res) => {
            if (res.data) {
              console.log(res.data);
              setDriver([res.data]);
            }
          })
          .catch((error) => {
            console.log(error);
            setDriver([]);
          });
      } else {
        console.log("no driver found");
      }
    }
  }, [booking]);

  const onDeleteHandler = (date) => {
    setOpenDeleteBooking(true);
    console.log(Bookings);

    // console.log("setOpenDetailBooking =" + openDetailBooking);
    // console.log("check date" + date);
  };
  const onCloseDialog = () => {
    setOpenDeleteBooking(false);
  };
  const onClickUpdate = async () => {
    const myData = {
      id: booking.idDeliverySampleShuttle,
      idDriver: booking.idDriver,
    };

    const data = await dispatch(postUpdateDeliveryStatus(myData));

    if (data.status === 200) {
      props.history.push(`/sampleshuttle`);
      // props.history.push(`/sample`);
    }
  };
  const onClickEditList = () => {
    setIsEditList(true);
    setIsNewList(false);
    props.history.push({
      pathname: `/sampleshuttle/NewSample/formDetailFlight`,
      state: {
        isEditList: isEditList,
        isNewList: isNewList,
        bookings: booking,
      },
    });
  };

  const onClickDeleteList = async () => {
    const id = booking.idDeliverySampleShuttle;
    const myData = {
      id: id,
    };
    console.log("deleting : " + myData.id);
    const data = await dispatch(postDeleteDeliverySampleshuttle(myData))
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Delete Success", { variant: "success" });
          props.history.push(`/sampleshuttle`);
        } else {
          enqueueSnackbar("Delete Failed", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    console.log("8");
    setOpenModal(true);
  };

  const takeAtl = (index) => {
    const paths = JSON.parse(booking.path);
    return paths[index].fileName;
  };

  const checkIsPicture = (index) => {
    const paths = JSON.parse(booking.path);
    const tailName = paths[index].fileName.split(".")[1];
    if (tailName === "jpg" || tailName === "png" || tailName === "jpeg") {
      return true;
    }
    return false;
  };

  const handleClickChip = () => {};
  const ComponentToPrint = (props) => (
    <div
      ref={props.innerRef}
      style={{
        width: "100%",
        padding: "10px",

        "@media print": {
          "@page": {
            size: "50mm 150mm",
            margin: "40mm",
          },
        },
      }}
    >
      <></>
      <Text
        style={{
          fontSize: "25px",
          marginTop: "15px",
          color: "black",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        รายละเอียด
      </Text>
      <Text
        style={{
          fontSize: "15px",
          marginTop: "15px",
          color: "black",
          textAlign: "center",
        }}
      >
        (งานที่ {booking.no})
      </Text>
      <DividerStyled />

      <p>รายละเอียดตัวอย่าง</p>

      <table style={{ borderCollapse: "collapse", width: "98%" }}>
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgb(214, 234, 248 )",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>ชื่อตัวอย่าง</th>
            <th>Lot Number</th>
            <th>Grade</th>
            <th>จุดรับต้นทาง</th>
            <th>จุดส่งปลายทาง</th>
            <th>รายละเอียดเพิ่มเติม</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid #eeeeee",
              textAlign: "center",
              height: "80px",
            }}
          >
            <td>{`${booking.nameSample}`}</td>
            <td>{`${booking.lotNumber}`}</td>
            <td>{`${booking.nameGrade}`}</td>
            <td>{`${booking.fromPlace}`}</td>
            <td>{`${booking.toPlace}`}</td>
            <td>{`${booking.detail}`}</td>
          </tr>
        </tbody>
      </table>

      <p>รายละเอียดผู้ส่ง</p>
      <table style={{ borderCollapse: "collapse", width: "98%" }}>
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgba(245, 183, 177 )",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>ชื่อผู้ส่ง</th>
            <th>เบอร์ผู้ส่ง</th>
            <th>วันที่ส่ง</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid #eeeeee",
              textAlign: "center",
              height: "50px",
            }}
          >
            <td>{`${booking.nameSender}`}</td>
            <td>{`${booking.phoneSender}`}</td>
            <td>{`${new Date(booking.date).toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}`}</td>
          </tr>
        </tbody>
      </table>

      <p>รายละเอียดผู้รับ</p>
      <table style={{ borderCollapse: "collapse", width: "98%" }}>
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgba(209, 242, 235 )",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>ชื่อผู้รับ</th>
            <th>เบอร์ผู้รับ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid #eeeeee",
              textAlign: "center",
              height: "50px",
            }}
          >
            <td>{`${booking.nameRecipient}`}</td>
            <td>{`${booking.phoneRecipient}`}</td>
          </tr>
        </tbody>
      </table>
      <p>รายละเอียดคนขับรถ</p>
      <table style={{ borderCollapse: "collapse", width: "98%" }}>
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgba(237, 187, 153)",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>ชื่อคนขับ</th>
            <th>เบอร์คนขับ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid #eeeeee",
              textAlign: "center",
              height: "50px",
            }}
          >
            {isDriver[0] != undefined && (
              <>
                <td>{`${isDriver[0].firstname_TH} ${isDriver[0].lastname_TH}`}</td>
                <td>{`${isDriver[0].telephoneMobile}`}</td>
              </>
            )}
            {isDriver[0] === undefined && (
              <>
                <td>{`กำลังหาคนขับรถ`}</td>
                <td>{`กำลังหาคนขับรถ`}</td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <p>สถานะการส่งตัวอย่าง</p>
      <table style={{ borderCollapse: "collapse", width: "98%" }}>
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgb(247,150,55)",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>สถานะ</th>
            <th>วันที่ส่งของสำเร็จ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid #eeeeee",
              textAlign: "center",
              height: "50px",
            }}
          >
            {booking.status === "ได้รับสินค้าเรียบร้อย" && (
              <td style={{ color: "#24944f" }}>{`${booking.status}`}</td>
            )}
            {booking.status === "รอรับสินค้า" && (
              <td style={{ color: "#d42121" }}>{`${booking.status}`}</td>
            )}
            {booking.status === "รับสินค้าเรียบร้อย" && (
              <td style={{ color: "#dfcf20" }}>{`${booking.status}`}</td>
            )}
            {booking.status === "ส่งสินค้าเรียบร้อย" && (
              <td style={{ color: "#d47721" }}>{`${booking.status}`}</td>
            )}
            {booking.status === "ส่งสินค้าเรียบร้อย" ||
              (booking.status === "ได้รับสินค้าเรียบร้อย" && (
                <>
                  <td>{` ${new Date(booking.date).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}`}</td>
                </>
              ))}
            {booking.status !== "ได้รับสินค้าเรียบร้อย" && <td>{`---`}</td>}
          </tr>
        </tbody>
      </table>
      <p style={{ marginTop: "200px" }}>รูปภาพเพิ่มเติม</p>
      <table
        style={{ borderCollapse: "collapse", width: "98%", marginTop: "20px" }}
      >
        <thead>
          <tr
            style={{
              fontSize: "18px",
              padding: "12px",
              border: "1px solid #eeeeee",
              backgroundColor: "rgba(214, 234, 248 )",
              color: "color: #220f5f",
              height: "50px",
            }}
          >
            <th>รูปภาพเพิ่มเติม</th>
          </tr>
        </thead>

        {booking.urlFiles != undefined && (
          <div key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}>
            {booking.urlFiles != null &&
              booking.urlFiles.map((value, index) => (
                <img
                  src={value}
                  style={{ height: "25%", width: "25%", margin: "5px" }}
                />
              ))}
          </div>
        )}
        {booking.urlFiles === undefined && (
          <div key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}>
            <>
              <p>ไม่มีรูปภาพ</p>
            </>
          </div>
        )}
      </table>
    </div>
  );
  const ComponentToShow = (props) => (
    <DivCoverReturn className="page">
      <PaperStyled>
        <GridContainer container>
          {/* <BackButton disableRipple onClick={props.history.goBack}>
          <ArrowBackIcon className="Icon" />
        </BackButton> */}

          {/* <Grid container item xs={12} md={6}>
            
          </Grid> */}

          <Grid item xs={12} lg={12}>
            <Text
              style={{
                fontSize: "30px",
                marginTop: "15px",
                color: "black",
                textAlign: "center",
              }}
            >
              รายละเอียด
            </Text>
            <Text
              style={{
                fontSize: "24px",
                marginTop: "15px",
                color: "black",
                textAlign: "center",
              }}
            >
              (งานที่ {booking.no})
            </Text>
          </Grid>

          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12}>
              <Mumu>รายละเอียดตัวอย่าง</Mumu>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Text>{`ชื่อตัวอย่าง :`}</Text>
              <TextFrom>{`${booking.nameSample}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Text>{`Grade :`}</Text>
              <TextFrom>{`${booking.nameGrade}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Text>{`LotNumber :`}</Text>
              <TextFrom>{`${booking.lotNumber}`}</TextFrom>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                style={{ weight: "40px", height: "40px", marginRight: "3px" }}
                src={"/assets/Image/Icon/orderSender.png"}
                alt="Logo"
              />
              <Text>{`จุดรับของต้นทาง :`}</Text>

              {allSite &&
                booking &&
                allSite.map((value) => {
                  if (value.idScgSite == booking.fromPlace) {
                    if (value.noSite !== null)
                      return (
                        <TextFrom
                          key={value.idScgSite}
                        >{`Site${value.noSite}: ${value.nameSite}`}</TextFrom>
                      );
                    else
                      return (
                        <TextFrom
                          key={value.idScgSite}
                        >{`${value.nameSite}`}</TextFrom>
                      );
                  }
                })}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                style={{ weight: "40px", height: "40px", marginRight: "3px" }}
                src={"/assets/Image/Icon/orderRe.png"}
                alt="Logo"
              />
              <Text>{`จุดส่งของปลายทาง : `}</Text>
              {allSite &&
                booking &&
                allSite.map((value) => {
                  if (value.idScgSite == booking.toPlace) {
                    if (value.noSite !== null)
                      return (
                        <TextFrom
                          key={value.idScgSite}
                        >{`Site${value.noSite}: ${value.nameSite}`}</TextFrom>
                      );
                    else
                      return (
                        <TextFrom
                          key={value.idScgSite}
                        >{`${value.nameSite}`}</TextFrom>
                      );
                  }
                })}
            </Grid>
            <Grid item xs={12}>
              {/* <Text>{`รายละเอียดเพิ่มเติม : ${booking.detail}`}</Text> */}
              <Text>{`รายละเอียดเพิ่มเติม : `}</Text>
              <TextFrom>{`${booking.detail}`}</TextFrom>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "grid", alignItems: "center" }}
            >
              <Text style={{ marginRight: "5px" }}>{`ไฟล์เพิ่มเติม : `}</Text>

              {(booking.urlFiles != undefined || booking.isExp !== 1) && (
                <div
                  key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                >
                  <Stack direction="column" spacing={1}>
                    {booking.urlFiles != null && (
                      <>
                        <Stack direction="row" spacing={1}>
                          {booking.urlFiles.map((value, index) => (
                            <>
                              {checkIsPicture(index) === true && (
                                <a
                                  href={value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    height: "20%",
                                    width: "20%",
                                    margin: "5px",
                                  }}
                                >
                                  <img
                                    src={`${value}`}
                                    alt={`${takeAtl(index)}`}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      margin: "5px",
                                    }}
                                  />
                                </a>
                              )}
                            </>
                          ))}
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          {booking.urlFiles.map((value, index) => (
                            <>
                              {checkIsPicture(index) === false && (
                                <Chip
                                  key={`file_${index}`}
                                  label={`${takeAtl(index)}`}
                                  color="primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  component="a"
                                  href={value}
                                  clickable
                                />
                              )}
                            </>
                          ))}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </div>
              )}
              {(booking.urlFiles === undefined ||
                booking.urlFiles.length === 0) && (
                <div
                  key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                >
                  <>
                    <TextFrom>ไม่มีไฟล์</TextFrom>
                  </>
                </div>
              )}
              {booking.isExp === 1 && (
                <div
                  key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                >
                  <>
                    <TextFrom>ขออภัย: ไฟล์หมดอายุ</TextFrom>
                  </>
                </div>
              )}
            </Grid>

            <DividerStyled />
            <Grid item xs={12}>
              <Mumu>รายละเอียดผู้ส่ง</Mumu>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
              <Text>{`ชื่อผู้ส่ง :`}</Text>
              <TextFrom>{`${booking.nameSender}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
              <Text>{`เบอร์ผู้ส่ง :`}</Text>
              <TextFrom>{`${booking.phoneSender}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Text>{`วันที่ส่ง :`}</Text>
              <TextFrom>{`${new Date(booking.date).toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Text>{`เวลาที่ส่ง :`}</Text>
              <TextFrom>{`${new Date(booking.date).toLocaleTimeString("th-TH", {
                hour: "2-digit",
                minute: "2-digit",
              })}`}</TextFrom>
            </Grid>

            <DividerStyled />
            <Grid item xs={12}>
              <Mumu>รายละเอียดผู้รับ</Mumu>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
              <Text>{`ชื่อผู้รับ : `}</Text>
              <TextFrom>{`${booking.nameRecipient}`}</TextFrom>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
              <Text>{`เบอร์ผู้รับ :`}</Text>
              <TextFrom>{`${booking.phoneRecipient}`}</TextFrom>
            </Grid>
            {booking.status === "ได้รับสินค้าเรียบร้อย" && (
              <>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`วันที่รับ :`}</Text>
                  <TextFrom>
                    {booking.completedDate &&
                      new Date(booking.completedDate).toLocaleDateString(
                        "th-TH",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                  </TextFrom>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`เวลาที่รับ :`}</Text>
                  <TextFrom>
                    {booking.completedDate &&
                     new Date(booking.completedDate).toLocaleTimeString("th-TH", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}                    
                  </TextFrom>
                </Grid>
              </>
            )}
            <DividerStyled />

            <Grid item xs={12}>
              <Mumu>รายละเอียดคนขับรถ</Mumu>
            </Grid>
            {isDriver[0] != undefined && (
              <>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                  <Text>{`ชื่อคนขับ : `}</Text>
                  <TextFrom>{`${isDriver[0].firstname_TH} ${isDriver[0].lastname_TH}`}</TextFrom>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                  <Text>{`เบอร์คนขับ : `}</Text>
                  <TextFrom>{`${isDriver[0].telephoneMobile}`}</TextFrom>
                </Grid>
              </>
            )}
            {isDriver[0] === undefined && (
              <>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                  <Text>{`ชื่อคนขับรถ : `}</Text>
                  <TextFrom
                    style={{ color: "#d42121" }}
                  >{`กำลังหาคนขับรถ`}</TextFrom>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                  <Text>{`เบอร์คนขับรถ : `}</Text>
                  <TextFrom
                    style={{ color: "#d42121" }}
                  >{`กำลังหาคนขับรถ`}</TextFrom>
                </Grid>
              </>
            )}
            <DividerStyled />

            <Grid item xs={12}>
              <Mumu>สถานะการส่งตัวอย่าง</Mumu>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              {/* <Text>{`สถานะ : ${booking.status}`}</Text> */}
              <Text>{`สถานะ : `}</Text>
              {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                <TextFrom
                  style={{ color: "#24944f" }}
                >{`${booking.status}`}</TextFrom>
              )}
              {booking.status === "รอรับสินค้า" && (
                <TextFrom
                  style={{ color: "#d42121" }}
                >{`${booking.status}`}</TextFrom>
              )}
              {booking.status === "รับสินค้าเรียบร้อย" && (
                <TextFrom
                  style={{ color: "#dfcf20" }}
                >{`${booking.status}`}</TextFrom>
              )}
              {booking.status === "ส่งสินค้าเรียบร้อย" && (
                <TextFrom
                  style={{ color: "#d47721" }}
                >{`${booking.status}`}</TextFrom>
              )}
            </Grid>

            {booking.status === "ส่งสินค้าเรียบร้อย" ||
            booking.status === "ได้รับสินค้าเรียบร้อย" ? (
              <>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`ส่งของสำเร็จ :`}</Text>
                  <TextFrom>
                    {booking.sendDate &&
                      ` ${new Date(booking.sendDate).toLocaleDateString(
                        "th-TH",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}`}
                  </TextFrom>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`เวลาที่ส่งสำเร็จ :`}</Text>
                  <TextFrom>
                    {booking.sendDate &&
                      `${new Date(booking.sendDate).toLocaleTimeString("th-TH", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`}
                  </TextFrom>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`ส่งของสำเร็จ :`}</Text>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`เวลาที่ส่งของสำเร็จ :`}</Text>
                </Grid>
              </>
            )}
          </GridContainer>
        </GridContainer>
      </PaperStyled>
      <div></div>

      {isOpenFoodMenu && (
        <FoodMenuModal
          open={isOpenFoodMenu}
          onClose={() => {
            setIsOpenFoodMenu(false);
          }}
          data={booking}
        />
      )}
    </DivCoverReturn>
  );
  return (
    <Container maxWidth="xl">
      {booking && (
        <Root>
          <DivCoverReturn className="page">
            <PaperStyledTop
              style={{ marginTop: "20px", marginBottom: "-80px" }}
            >
              <GridContainer
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <BackButton disableRipple onClick={props.history.goBack}>
                    <ArrowBackIcon
                      className="Icon"
                      style={{ color: "#99A8B2" }}
                    />
                  </BackButton>
                </Grid>
                <Grid>
                  <Stack direction="row" spacing={1}>
                    <>
                      <IconButton aria-label="edit">
                        <EditIcon
                          style={{
                            width: "50px",
                            height: "50px",
                            // cursor: "pointer",
                            // color: isHovered ? "#101213" : "#99A8B2",
                            // marginRight: "15px",
                            // marginTop: "10px",
                          }}
                          onClick={onClickEditList}
                          isEditList={isEditList}
                          isNewList={isNewList}
                        />
                      </IconButton>
                      <IconButton aria-label="delete">
                        <DeleteIcon
                          style={{
                            width: "50px",
                            height: "50px",
                            // cursor: "pointer",
                            // color: isHovered ? "#101213" : "#99A8B2",
                            // marginRight: "15px",
                            // marginTop: "10px",
                          }}
                          onClick={onDeleteHandler}
                        />
                      </IconButton>
                    </>
                    <ReactToPrint
                      // trigger={() => <a href="#">Print this out!</a>}
                      trigger={() => (
                        <LocalPrintshopIcon
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            color: isHovered ? "#101213" : "#99A8B2",
                            marginRight: "15px",
                            marginTop: "10px",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          Print this out!
                        </LocalPrintshopIcon>
                      )}
                      content={() => componentRef.current}
                    />
                  </Stack>
                </Grid>
              </GridContainer>
            </PaperStyledTop>

            <ComponentToShow />
            {/* <PaperStyled> */}
            <GridContainer>
              {booking.status === "ส่งสินค้าเรียบร้อย" && (
                <>
                  <Grid item xs={12} md={6} marginBottom={"20px"}>
                    <ViewButton
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                      }}
                      onClick={onClickUpdate}
                    >
                      กดได้รับสินค้า
                    </ViewButton>
                  </Grid>
                </>
              )}
              {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                <>
                  <Grid item xs={12} md={6} marginBottom={"20px"}>
                    <ViewButton
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                      }}
                      disabled={true}
                      onClick={onClickUpdate}
                    >
                      กดได้รับสินค้า
                    </ViewButton>
                  </Grid>
                </>
              )}
              {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                >
                  <ViewButtonMap
                    disabled={true}
                    onClick={onClickOpenModal}
                    style={{ fontSize: "18px" }}
                  >
                    แสดงแผนที่
                  </ViewButtonMap>
                </Grid>
              )}
              {booking.status != "ได้รับสินค้าเรียบร้อย" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <ViewButtonMap
                    onClick={onClickOpenModal}
                    style={{ fontSize: "18px" }}
                  >
                    แสดงแผนที่
                  </ViewButtonMap>
                </Grid>
              )}
            </GridContainer>
            {/* </PaperStyled> */}

            {booking && (
              <GoogleApi
                open={openModal}
                onCloseHandle={onCloseModalHandle}
                inforInCar={booking}
              />
            )}
            <div style={{ display: "none" }}>
              <ComponentToPrint innerRef={componentRef} />
            </div>
          </DivCoverReturn>

          <DeleteModal
            open={openDeleteBooking}
            onCloseDeleteModalHandle={onCloseDialog}
            onClickDelete={onClickDeleteList}
            label={"ลบรายการจองรถรับส่งตัวอย่าง"}
          />
        </Root>
      )}
    </Container>
  );
};

export default CardSampleDetail;
