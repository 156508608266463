import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import React, { useState } from "react";

import {
  Typography,
  Avatar,
  TableRow,
  TableCell,
  useMediaQuery,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import { padding } from "@mui/system";

const useStyles = makeStyles((theme) => ({}));

const RowStyled = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: "120px",
  cursor: "pointer",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    boxShadow: "0 0 10px 0 rgba(73, 73, 73, 0.8)",
  },
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 0",
  },
}));
const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
}));

const TextNormal = styled(Typography)(({ theme, color }) => ({
  fontSize: "16px",
  color: theme.palette.text[color] || theme.palette.text["primary"],
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const TextSmall = styled(Typography)(({ theme, color }) => ({
  fontSize: "14px",
  color: theme.palette.text[color] || theme.palette.text["third"],
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StatusText = styled(Typography)(({ theme, status }) => ({
  fontSize: "15px",
  textAlign: "left",
  color:
    status === "ได้รับสินค้าเรียบร้อย"
      ? "#4CAF50" // Green
      : status === "ส่งสินค้าเรียบร้อย"
      ? "#2196F3" // Blue
      : status === "รับสินค้าเรียบร้อย"
      ? "#FF9800" // Orange
      : status === "รอรับสินค้า"
      ? "#F44336" // Red
      : "inherit", // Default color
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const SiteInfo = ({ iconColor, siteNo, siteName }) => (
  <div
    style={{ display: "flex", alignItems: "flex-start", marginBottom: "5px" }}
  >
    <LocationOnIcon
      sx={{
        height: "20px",
        color: iconColor,
        verticalAlign: "middle",
        marginRight: "4px",
      }}
    />
    <TextSmall color="primary">{"Site " + siteNo + ": " + siteName}</TextSmall>
  </div>
);

const CardBooking = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return isMobile ? (
    <RowStyled
      sx={{
        "& .MuiTableCell-root": {
          width: "100%",
        },
      }}
      status={props.booking.deliveryStatus}
      onClick={() =>
        props.onClickCardHandler(
          props.typeOfBooking,
          props.booking.idDeliverySampleShuttle
        )
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <AvatarStyled
          src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
          alt="Profile"
          style={{ width: "50px", height: "50px", marginBlock: "12px" }}
        />
        <div
          style={{
            paddingLeft: "12px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
              <div
                style={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <TextNormal>
                  <strong>ผู้ส่ง </strong>
                  {props.booking.nameSender}
                </TextNormal>
                <TextSmall style={{ fontSize: "12px", color: "#757575" }}>
                  {`เบอร์โทรศัพท์: ${props.booking.phoneSender}`}
                </TextSmall>
              </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                marginTop: "10px",
                maxWidth: "100px",
                justifyContent: "flex-end",
              }}
            >
              {props.booking.deliveryStatus === "ได้รับสินค้าเรียบร้อย" ? (
                <CheckCircleRoundedIcon
                  style={{ color: "#4CAF50", fontSize: "18px" }}
                />
              ) : (
                <HourglassTopRoundedIcon
                  style={{
                    fontSize: "18px",
                    color:
                      props.booking.deliveryStatus === "ส่งสินค้าเรียบร้อย"
                        ? "#2196F3"
                        : props.booking.deliveryStatus === "รับสินค้าเรียบร้อย"
                        ? "#FF9800"
                        : props.booking.deliveryStatus === "รอรับสินค้า"
                        ? "#F44336"
                        : "inherit",
                  }}
                />
              )}
              <StatusText
                status={props.booking.deliveryStatus}
                style={{ fontSize: "14px", fontWeight: "500" }}
              >
                {props.booking.deliveryStatus}
              </StatusText>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <TextNormal style={{ fontSize: "14px" }}>
              <strong>ชื่อตัวอย่าง</strong> {props.booking.nameSample}
            </TextNormal>
            <TextNormal style={{ fontSize: "14px" }}>
              <strong>วันที่ส่ง</strong>
              {` ${dayjs(props.booking.date).format("D MMM YYYY")} ${
                props.booking.startTime
              } น.`}
            </TextNormal>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SiteInfo
              iconColor="green"
              siteNo={props.booking.fromPlaceNoSite}
              siteName={props.booking.fromPlaceName}
            />
            <SiteInfo
              iconColor="red"
              siteNo={props.booking.toPlaceNoSite}
              siteName={props.booking.toPlaceName}
            />
          </div>
        </div>
      </div>
    </RowStyled>
  ) : (
    <RowStyled
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "none",
          borderTop: "none",
        },
      }}
      status={props.booking.deliveryStatus}
      onClick={() =>
        props.onClickCardHandler(
          props.typeOfBooking,
          props.booking.idDeliverySampleShuttle
        )
      }
    >
      <TableCell>
        <Typography
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <AvatarStyled
            src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
            alt="1"
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <TextNormal>{`${props.booking.nameSender}`}</TextNormal>
            <TextSmall>
              {`${"เบอร์โทรศัพท์: " + props.booking.phoneSender}`}
            </TextSmall>
          </div>
        </Typography>
      </TableCell>
      <TableCell>
        <TextNormal>{`${props.booking.nameSample}`}</TextNormal>
      </TableCell>
      <TableCell>
        <TextNormal>
          {`${dayjs(props.booking.date).format("D MMM YYYY")}`}
        </TextNormal>
        <TextSmall>{`${props.booking.startTime} น.`}</TextSmall>
      </TableCell>
      <TableCell>
        <SiteInfo
          iconColor="green"
          siteNo={props.booking.fromPlaceNoSite}
          siteName={props.booking.fromPlaceName}
        />
        <SiteInfo
          iconColor="red"
          siteNo={props.booking.toPlaceNoSite}
          siteName={props.booking.toPlaceName}
        />
      </TableCell>
      <TableCell>
        <TextNormal sx={{ display: "flex" }}>
          {props.booking.deliveryStatus === "ได้รับสินค้าเรียบร้อย" ? (
            <CheckCircleRoundedIcon
              style={{ color: "#4CAF50", marginRight: "5px" }}
            />
          ) : (
            <HourglassTopRoundedIcon
              style={{
                color:
                  props.booking.deliveryStatus === "ส่งสินค้าเรียบร้อย"
                    ? "#2196F3" // Blue
                    : props.booking.deliveryStatus === "รับสินค้าเรียบร้อย"
                    ? "#FF9800" // Orange
                    : props.booking.deliveryStatus === "รอรับสินค้า"
                    ? "#F44336" // Red
                    : "inherit",
                marginRight: "5px",
              }}
            />
          )}
          <StatusText status={props.booking.deliveryStatus}>
            {props.booking.deliveryStatus}
          </StatusText>
        </TextNormal>
      </TableCell>
    </RowStyled>
  );
};

export default CardBooking;
