import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@mui/material/Container";
import { styled } from "@mui/styles";

import {
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";
import UnicornLoading from "../../assets/loading.gif";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm, Controller } from "react-hook-form";

import {
  getAllCrossAreaCarBookingsByFilterByIdDriver,
  getCrossAreaCarBookingByIdDriver,
} from "../../../../actions/crossAreaCarBooking";
import {
  getCrossAreaCarPoolBookingByFilterByIdDriver,
  getCrossAreaCarPoolBookingByIdDriver,
} from "../../../../actions/crossAreaCarPoolBooking";
import {
  getInAreaCarBookingByFilterByIdDriver,
  getInAreaCarBookingByIdDriver,
} from "../../../../actions/inAreaCarBooking";
import {
  getDeliveryCarBookingByFilterByDriver,
  getDeliveryCarBookingByIdDriver,
  getDeliveryCarBookingByIdUser,
} from "../../../../actions/deliveryCarBooking";

import { getAllCrossAreaCarBookingsByFilter } from "../../../../actions/crossAreaCarBooking";
import { getCrossAreaCarPoolBookingByFilter } from "../../../../actions/crossAreaCarPoolBooking";
import { getInAreaCarBookingByFilter } from "../../../../actions/inAreaCarBooking";
import { getMeetingRoomBookingByFilter } from "../../../../actions/meetingRoomBooking";
import { getDeliveryCarBookingByFilter } from "../../../../actions/deliveryCarBooking";
import {
  getAllDriverBookingByIdUser,
  getDriverBookingByFilterByIdDriver,
  getDriverBookingByFilterByIdUser,
  getDriverBookingByIdDriver,
} from "../../../../actions/driverBooking";
import ButtonBlue from "../../shared/buttonBlue";
import CrossArea from "./crossAreaCar";

import CrossAreaPoolCar from "./crossAreaPoolCar";
import InAreaCar from "./inAreaCar";
import DeliverySampleShuttleCar from "./deliverySampleShuttle";
import DeliveryCar from "./deliveryCar";
import DriverBooking from "./driver";
import BetweenSiteCar from "./betweenSiteCar";
import { useMediaQuery } from "@mui/material";

import {
  getDeliverySampleShuttleByFilterByIdDriver,
  getDeliverySampleshuttleByIdDriver,
} from "../../../../actions/deliverySampleshuttle";
import {
  getBetweenSiteCarBookingByIdDriver,
  getBetweenSiteCarByFilterByIdDriver,
} from "../../../../actions/betweenSiteCarBooking";
import { width } from "@mui/system";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));
const DivCoverReturn = styled("div")(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  marginTop: "20px",
  height: "calc(100% - 64px)",

  "& .ButtonTypeNotClicked": {
    backgroundColor: "#B2BABB ",
    color: "white",
    fontWeight: "bold",
    ["&:hover"]: {
      borderBottom: "0px",
      backgroundColor: "#D5DBDB",
    },
  },
}));
const HeaderOfPage = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
  // alignItems: "center",
});
const PartOfContent = styled("div")({
  width: "100%",
  height: "calc(100% - 80px)",
  // display: "flex",

  // alignItems: "center",
});

const Title = styled(Typography)({
  // textAlign: "center",
  fontSize: "34px",
  // marginBottom: "20px",
});

const ButtonType = styled(Button)({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "16px",
  borderRadius: "4px 4px 0 0",
  zIndex: "10",
  backgroundColor: "#FBFCFC",
  border: "1px solid #EEEEEE",
  borderBottom: "0",
  marginBottom: "-2px",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: "#FBFCFC",
  },
});
const ViewButton = styled(Button)(({ theme }) => ({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "15px",
  borderRadius: "10px 10px 10px 10px",
  zIndex: "10",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid #00bb77",
  borderBottom: "0",
  marginBottom: "-2px",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: theme.palette.primary.dark,
  },
}));

const NavDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "10px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const AllCards = styled("div")({
  height: "calc(100% - 153px)",
  padding: "20px",
  marginBottom: "10px",
  paddingBottom: "0",
  paddingTop: "20px",
  border: "1px solid #EEEEEE",
  zIndex: "1",
  backgroundColor: "#FBFCFC",
});
const TabsStyled = styled(Tabs)({
  "& .MuiTab-root": {
    fontSize: "16px",
    fontWeight: "500",
    minWidth: "150px",
    minHeight: "60px",
  },

  // "& .MuiTabs-flexContainer": {
  //   justifyContent: "center",
  // },

  "& .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-34": {
    height: "3px",
  },
});

const TabStyled = styled(Tab)({
  // width: "150px",
  // border: "1px solid black",
  // borderLeft: "0",
  fontSize: "14px",
  height: "60px",
  // "&.Mui-selected": {
  //   borderBottom: "2px solid #000", // สีและความหนาของเส้นใต้
  // },
});
const Image = styled("img")({
  width: "100%",
  height: "80%",
});

const SpaceOfAllCards = styled("div")({
  height: "95%",
  padding: "5px 4px 0px 4px",
  overflowY: "auto",
});
const NavBar = styled("div")({
  display: "flex",
  width: "100%",
});

const MyListsForDriver = (props) => {
  const dispatch = useDispatch();

  const { isFetching: isFetchingBetweenSiteCar, result: betweenSiteCar } =
    useSelector((state) => state.betweenSiteCar);
  const { isFetching: isFetchingCrossAreaBookings, result: crossAreaBookings } =
    useSelector((state) => state.crossAreaCar);
  const {
    isFetching: isFetchingCrossAreaPoolBookings,
    result: crossAreaPoolBookings,
  } = useSelector((state) => state.crossAreaCarPool);
  const { isFetching: isFetchingInAreaBookings, result: inAreaBookings } =
    useSelector((state) => state.inAreaCar);
  const { isFetching: isFetchingDeliveryBookings, result: deliveryBookings } =
    useSelector((state) => state.deliveryCar);
  const { isFetching: isFetchingDeliverySampleShuttleCar, result } =
    useSelector((state) => state.deliverySampleShuttleCar);
  const { isFetching: isFetchingDriverBookings, result: driverBookings } =
    useSelector((state) => state.driverBooking);

  const [typeOfBooking, setTypeOfBooking] = useState("CrossArea");
  const [typeOfBookingPath, setTypeOfBookingPath] = useState(
    "cross_area_car_booking"
  );
  const { user: User } = useSelector((state) => state.auth);
  const [showHistory, setHistory] = useState(0);

  const { register, getValues, control } = useForm();

  const [isStartDate, setStartDate] = useState(null);
  const [isEndDate, setEndDate] = useState(null);

  const [deliverySampleStatus, setDeliverySampleStatus] =
    useState("รอรับสินค้า");
  const deliverySampleShuttleCar = result[deliverySampleStatus];
  const [isSearchActive, setSearchStatus] = useState(false);
  const [filteredResult, setFilteredResult] = useState(
    deliverySampleShuttleCar
  );

  useEffect(() => {
    let path = SplitPathName();
    setTypeOfBookingPath(path);
    dispatch(getCrossAreaCarBookingByIdDriver(User.idUser));
    dispatch(getCrossAreaCarPoolBookingByIdDriver(User.idUser));
    dispatch(getInAreaCarBookingByIdDriver(User.idUser));
    dispatch(getDeliveryCarBookingByIdDriver(User.idUser));
    dispatch(
      getDeliverySampleshuttleByIdDriver(User.idUser, deliverySampleStatus)
    );
    // dispatch(getDeliverySampleshuttleByIdDriver(User.idUser));
    dispatch(getBetweenSiteCarBookingByIdDriver(User.idUser));
    dispatch(getDriverBookingByIdDriver(User.idUser));
    if (path === "cross_area_car_booking") {
      setTypeOfBooking("CrossArea");
    } else if (path === "cross_area_car_pool_booking") {
      setTypeOfBooking("CrossAreaPool");
    } else if (path === "in_area_car_booking") {
      setTypeOfBooking("InArea");
    } else if (path === "delivery_car_booking") {
      setTypeOfBooking("Delivery");
    } else if (path === "delivery_sample_shuttle_car_booking") {
      setTypeOfBooking("DeliverySampleShuttleCar");
    } else if (path === "between_site_car_booking") {
      setTypeOfBooking("BetweenSiteCar");
    } else if (path === "driver_booking") {
      setTypeOfBooking("DriverBooking");
    }
  }, []);

  useEffect(() => {
    if (typeOfBooking === "CrossArea") {
      if (!crossAreaBookings) {
        dispatch(getCrossAreaCarBookingByIdDriver(User.idUser));
      }
    } else if (typeOfBooking === "CrossAreaPool") {
      if (!crossAreaPoolBookings) {
        dispatch(getCrossAreaCarPoolBookingByIdDriver(User.idUser));
      }
    } else if (typeOfBooking === "InArea") {
      if (!inAreaBookings) {
        dispatch(getInAreaCarBookingByIdDriver(User.idUser));
      }
    } else if (typeOfBooking === "DeliverySampleShuttleCar") {
      if (!deliverySampleShuttleCar) {
        dispatch(
          getDeliverySampleshuttleByIdDriver(User.idUser, deliverySampleStatus)
        );
      }
    } else if (typeOfBooking === "BetweenSiteCar") {
      if (!betweenSiteCar) {
        dispatch(getBetweenSiteCarBookingByIdDriver(User.idUser));
      }
    } else if (typeOfBooking === "DriverBooking") {
      if (!driverBookings) {
        dispatch(getAllDriverBookingByIdUser(User.idUser));
      }
    } else {
      if (!deliveryBookings) {
        dispatch(getDeliveryCarBookingByIdUser(User.idUser));
      }
    }
  }, [
    typeOfBooking,
    showHistory,
    deliverySampleStatus,
    deliverySampleShuttleCar,
  ]);
  useEffect(() => {
    if (deliverySampleShuttleCar && isSearchActive) {
      const startDate =
        isStartDate === null
          ? null
          : isStartDate.$d.toLocaleString("af-ZA").slice(0, 10);
      const endDate =
        isEndDate === null
          ? null
          : isEndDate.$d.toLocaleString("af-ZA").slice(0, 10);
      const nameSender = getValues("name");

      const finishedFilteredResult = filterDeliverySamples(
        nameSender,
        startDate,
        endDate,
        deliverySampleShuttleCar
      );
      setFilteredResult(finishedFilteredResult);
    }
  }, [deliverySampleShuttleCar, isSearchActive, deliverySampleStatus]);

  useEffect(() => {
    if (showHistory === 1) {
      handleStatusFilter("ได้รับสินค้าเรียบร้อย");
    } else {
      setDeliverySampleStatus("รอรับสินค้า");
    }
  }, [showHistory]);

  const onClickSearch = () => {
    const startDate =
      isStartDate === null
        ? null
        : isStartDate.$d.toLocaleString("af-ZA").slice(0, 10);
    const endDate =
      isEndDate === null
        ? null
        : isEndDate.$d.toLocaleString("af-ZA").slice(0, 10);
    if (typeOfBooking === "CrossArea") {
      dispatch(
        getAllCrossAreaCarBookingsByFilterByIdDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    } else if (typeOfBooking === "CrossAreaPool") {
      dispatch(
        getCrossAreaCarPoolBookingByFilterByIdDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    } else if (typeOfBooking === "InArea") {
      dispatch(
        getInAreaCarBookingByFilterByIdDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    } else if (typeOfBooking === "BetweenSiteCar") {
      dispatch(
        getBetweenSiteCarByFilterByIdDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    } else if (typeOfBooking === "DriverBooking") {
      dispatch(
        getDriverBookingByFilterByIdDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    } else if (typeOfBooking === "DeliverySampleShuttleCar") {
      const nameSender = getValues("name");
      if (showHistory === 0) {
        if (startDate === null && endDate === null && nameSender === "") {
          setDeliverySampleStatus("รอรับสินค้า");
          setSearchStatus(false);
        } else {
          const finishedFilteredResult = filterDeliverySamples(
            nameSender,
            startDate,
            endDate,
            deliverySampleShuttleCar
          );
          setSearchStatus(true);
          setFilteredResult(finishedFilteredResult);
        }
      } else {
        if (startDate === null && endDate === null && nameSender === "") {
          setDeliverySampleStatus("ได้รับสินค้าเรียบร้อย");
          setSearchStatus(false);
        } else {
          const finishedFilteredResult = filterDeliverySamples(
            nameSender,
            startDate,
            endDate,
            deliverySampleShuttleCar
          );
          setSearchStatus(true);
          setFilteredResult(finishedFilteredResult);
        }
      }
    } else if (typeOfBooking === "Delivery") {
      dispatch(
        getDeliveryCarBookingByFilterByDriver({
          name: getValues("name"),
          enddate: endDate,
          startdate: startDate,
          idDriver: User.idUser,
        })
      );
    }
  };
  const SplitPathName = () => {
    return props.location.pathname.split("/")[2];
  };

  const onClickButtonType = (event, path) => {
    setTypeOfBookingPath(path);
    // console.log(path);
    props.history.push(`/myListForDriver/${path}`);
    let type;
    if (path === "cross_area_car_booking") {
      type = "CrossArea";
    } else if (path === "cross_area_car_pool_booking") {
      type = "CrossAreaPool";
    } else if (path === "in_area_car_booking") {
      type = "InArea";
    } else if (path === "delivery_car_booking") {
      type = "Delivery";
    } else if (path === "delivery_sample_shuttle_car_booking") {
      type = "DeliverySampleShuttleCar";
    } else if (path === "between_site_car_booking") {
      type = "BetweenSiteCar";
    } else if (path === "driver_booking") {
      type = "DriverBooking";
    }
    // console.log(type);
    setTypeOfBooking(type);
  };

  const onClickCardHandler = (type, booking, idImageBooking) => {
    props.history.push("/myListForDriver/booking_detail", {
      typeBooking: type,
      idBooking: booking,
      idImageBooking: idImageBooking,
      deliverySampleStatus: deliverySampleStatus,
    });
  };
  const onClickHistory = () => {
    // setSearchStatus(false);
    setHistory(1);
  };
  const onClickHistoryClose = () => {
    setHistory(0);
  };
  const handleStatusFilter = (sampleStatus) => {
    setDeliverySampleStatus(sampleStatus);
  };
  const filterDeliverySamples = (nameSender, startDate, endDate, data) => {
    let finalResult = data;
    if (nameSender) {
      finalResult = finalResult.filter((item) => {
        const itemName = item.nameSender ? item.nameSender.toLowerCase() : "";
        return itemName.includes(nameSender.toLowerCase());
      });
    }
    if (startDate !== null || endDate !== null) {
      finalResult = finalResult.filter((item) => {
        const itemDate = item.date.slice(0, 10);
        if (startDate !== null && endDate !== null) {
          return startDate <= itemDate && endDate >= itemDate;
        } else if (startDate !== null) {
          return startDate <= itemDate;
        } else if (endDate !== null) {
          return endDate >= itemDate;
        }
        return true;
      });
    }
    return finalResult;
  };

  return (
    <Container maxWidth="xl">
      <Root>
        <DivCoverReturn className="page">
          <HeaderOfPage>
            <Grid
              container
              sx={(theme) => ({
                // display: "flex",
                // justifyContent: "space-betceween",

                marginTop: "30px",
                marginBottom: "30px",
                // [theme.breakpoints.down("sm")]: {
                //   display: "grid",
                // },
                // [theme.breakpoints.down("md")]: {
                //   display: "grid",
                // },
              })}
            >
              <Grid item xs={12} sm={6}>
                {showHistory === 0 && <Title>สถานะรายการของฉัน</Title>}
                {showHistory === 1 && <Title>ประวัติการทำรายการของฉัน</Title>}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={(theme) => ({ textAlign: "right" })}
              >
                {showHistory === 0 && (
                  <ButtonBlue
                    variant="contained"
                    onClick={onClickHistory}
                    startIcon={<QueryBuilderRoundedIcon />}
                  >
                    ประวัติการทำรายการ
                  </ButtonBlue>
                )}
                {showHistory === 1 && (
                  <ButtonBlue
                    variant="contained"
                    onClick={onClickHistoryClose}
                    startIcon={
                      <img
                        src="/assets/Image/Icon/list.png"
                        alt="List Icon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    }
                  >
                    สถานะรายการ
                  </ButtonBlue>
                )}
              </Grid>
            </Grid>
            {/* <Title>รายการของฉัน</Title> */}

            <Grid
              container
              sx={(theme) => ({
                backgroundColor: "rgb(255, 255, 255)",
                height: "fit-content",
                color: "rgb(33, 43, 54)",
                boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
                overflow: "hidden",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                borderRadius: "20px",
                marginBottom: "20px",
                padding: "25px 30px 25px 30px",
                borderRadius: "15px",
                display: "flex",
                gap: "1rem",
              })}
            >
              <Grid
                container
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  [theme.breakpoints.down("md")]: {
                    gap: "1rem",
                  },
                })}
              >
                <Grid item xs={12} md={5} lg={4}>
                  <TextField
                    id="search"
                    type="search"
                    label="ค้นหาชื่อ"
                    inputProps={{ ...register("name") }}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="Startdate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          label="วันเริ่มต้น"
                          // clearable
                          inputFormat="DD MMMM YYYY"
                          value={isStartDate}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          onChange={(date) => setStartDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="Enddate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          label="วันสิ้นสุด"
                          // clearable
                          minDate={isStartDate}
                          inputFormat="DD MMMM YYYY"
                          value={isEndDate}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          onChange={(date) => setEndDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                  <ButtonBlue
                    variant="contained"
                    onClick={onClickSearch}
                    startIcon={<SearchIcon />}
                    style={{
                      width: useMediaQuery((theme) =>
                        theme.breakpoints.down("sm")
                      )
                        ? "100%"
                        : "auto",
                    }}
                  >
                    ค้นหา
                  </ButtonBlue>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <TabsStyled
                  value={typeOfBookingPath}
                  onChange={onClickButtonType}
                  aria-label="Tab-typeOfBooking"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <TabStyled
                    className="classes.TabLeft"
                    id="Tab-crossArea"
                    value="cross_area_car_booking"
                    label="รถข้ามพื้นที่เฉพาะ"
                  />
                  <TabStyled
                    id="Tab-crossAreaPool"
                    value="cross_area_car_pool_booking"
                    label="รถข้ามพื้นที่Pool"
                  />
                  <TabStyled
                    id="Tab-inArea"
                    value="in_area_car_booking"
                    label="รถในพื้นที่"
                  />
                  <TabStyled
                    id="Tab-deliveryItem"
                    value="delivery_car_booking"
                    label="รถรับส่งของ"
                  />
                  <TabStyled
                    id="Tab-deliverySampleShuttle"
                    value="delivery_sample_shuttle_car_booking"
                    label="รถรับส่งตัวอย่าง"
                  />
                  <TabStyled
                    id="Tab-betweenSite"
                    value="between_site_car_booking"
                    label="รถรับส่งระหว่างสถานที่"
                  />
                  <TabStyled
                    id="Tab-driverBooking"
                    value="driver_booking"
                    label="จองคนขับ"
                  />
                </TabsStyled>
              </Grid>
              <PartOfContent>
                {typeOfBooking === "CrossArea" && (
                  <>
                    {!isFetchingCrossAreaBookings ? (
                      <>
                        {crossAreaBookings && crossAreaBookings.length > 0 ? (
                          <CrossArea
                            booking={crossAreaBookings}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
                {typeOfBooking === "CrossAreaPool" && (
                  <>
                    {!isFetchingCrossAreaPoolBookings ? (
                      <>
                        {crossAreaPoolBookings &&
                        crossAreaPoolBookings.length > 0 ? (
                          <CrossAreaPoolCar
                            booking={crossAreaPoolBookings}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}

                {typeOfBooking === "InArea" && (
                  <>
                    {!isFetchingInAreaBookings ? (
                      <>
                        {inAreaBookings && inAreaBookings.length > 0 ? (
                          <InAreaCar
                            booking={inAreaBookings}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
                {typeOfBooking === "Delivery" && (
                  <>
                    {!isFetchingDeliveryBookings ? (
                      <>
                        {deliveryBookings && deliveryBookings.length > 0 ? (
                          <DeliveryCar
                            booking={deliveryBookings}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
                {typeOfBooking === "DeliverySampleShuttleCar" && (
                  <>
                    {!isFetchingDeliverySampleShuttleCar ? (
                      <>
                        {showHistory === 0 && (
                          <div
                            style={{
                              display: "flex",
                              paddingBlock: "24px",
                              gap: 10,
                            }}
                          >
                            <ButtonBlue
                              variant={
                                deliverySampleStatus === "รอรับสินค้า"
                                  ? "contained"
                                  : "outlined"
                              }
                              customColor="#F44336" 
                              sx={
                                deliverySampleStatus === "รอรับสินค้า"
                                  ? {
                                      '&:hover': {
                                        borderColor: "#d9392e",
                                        backgroundColor: "#d9392e",
                                      },
                                    }
                                  : {
                                      '&:hover': {
                                        backgroundColor: "#ff091105",
                                        borderColor: "#d9392e",
                                      },
                                    }
                              }
                              onClick={() => handleStatusFilter("รอรับสินค้า")}
                            >
                              รอรับสินค้า
                            </ButtonBlue>
                            <ButtonBlue
                              variant={
                                deliverySampleStatus === "รับสินค้าเรียบร้อย"
                                  ? "contained"
                                  : "outlined"
                              }
                              customColor="#FF9800" 
                              sx={
                                deliverySampleStatus === "รับสินค้าเรียบร้อย"
                                  ? {
                                      '&:hover': {
                                        borderColor: "#de8500",
                                        backgroundColor: "#de8500",
                                      },
                                    }
                                  : {
                                      '&:hover': {
                                        backgroundColor: "#ffb60005",
                                        borderColor: "#de8500",
                                      },
                                    }
                              }
                              onClick={() =>
                                handleStatusFilter("รับสินค้าเรียบร้อย")
                              }
                            >
                              รับสินค้าเรียบร้อย
                            </ButtonBlue>
                            <ButtonBlue
                              variant={
                                deliverySampleStatus === "ส่งสินค้าเรียบร้อย"
                                  ? "contained"
                                  : "outlined"
                              }
                              customColor="#2196F3"
                              sx={
                                deliverySampleStatus === "ส่งสินค้าเรียบร้อย"
                                  ? {
                                      '&:hover': {
                                        borderColor: "#1878c7",
                                        backgroundColor: "#1878c7",
                                      },
                                    }
                                  : {
                                      '&:hover': {
                                        backgroundColor: "#0088ff08",
                                        borderColor: "#1878c7",
                                      },
                                    }
                              }
                              onClick={() =>
                                handleStatusFilter("ส่งสินค้าเรียบร้อย")
                              }
                            >
                              ส่งสินค้าเรียบร้อย
                            </ButtonBlue>
                          </div>
                        )}
                        {deliverySampleShuttleCar &&
                        deliverySampleShuttleCar.length > 0 ? (
                          isSearchActive ? (
                            <DeliverySampleShuttleCar
                              booking={filteredResult}
                              typeOfBooking={typeOfBooking}
                              onClickCardHandler={onClickCardHandler}
                              showHistory={showHistory}
                              deliverySampleStatus={deliverySampleStatus}
                              searchStatus={isSearchActive}
                            />
                          ) : (
                            <DeliverySampleShuttleCar
                              booking={deliverySampleShuttleCar}
                              typeOfBooking={typeOfBooking}
                              onClickCardHandler={onClickCardHandler}
                              showHistory={showHistory}
                              deliverySampleStatus={deliverySampleStatus}
                              searchStatus={isSearchActive}
                            />
                          )
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
                {typeOfBooking === "BetweenSiteCar" && (
                  <>
                    {!isFetchingBetweenSiteCar ? (
                      <>
                        {betweenSiteCar && betweenSiteCar.length > 0 ? (
                          <BetweenSiteCar
                            booking={betweenSiteCar}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
                {typeOfBooking === "DriverBooking" && (
                  <>
                    {!isFetchingDriverBookings ? (
                      <>
                        {driverBookings && driverBookings.length > 0 ? (
                          <DriverBooking
                            booking={driverBookings}
                            typeOfBooking={typeOfBooking}
                            onClickCardHandler={onClickCardHandler}
                            showHistory={showHistory}
                          />
                        ) : (
                          <Typography
                            style={{ textAlign: "center", fontSize: "24px" }}
                          >
                            ไม่มีรายการ
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </Paper>
                    )}
                  </>
                )}
              </PartOfContent>
            </Grid>
          </HeaderOfPage>
        </DivCoverReturn>
      </Root>
    </Container>
  );
};

export default MyListsForDriver;
