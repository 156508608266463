import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import { Grid, Typography, Divider, Chip, Stack } from "@mui/material";

require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const MainText = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const TextFrom = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#212b36",
  fontWeight: "600",
  marginLeft: "5px",
}));

const DetailDeliverySampleShuttleCar = (props) => {
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  const checkIsPicture = (index) => {
    const paths = JSON.parse(booking.path);
    const tailName = paths[index].fileName.split(".")[1];
    if (tailName === "jpg" || tailName === "png" || tailName === "jpeg") {
      return true;
    }
    return false;
  };

  const takeAtl = (index) => {
    const paths = JSON.parse(booking.path);
    return paths[index].fileName;
  };

  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดผู้รับ</MainText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้รับ : ${booking.nameRecipient}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ผู้รับ : ${booking.phoneRecipient}`}</Text>
            </Grid>
            {booking.sender && (
              <Grid item xs={12} md={6}>
                <Text>{`email : ${booking.senderEmail}`}</Text>
              </Grid>
            )}
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดตัวอย่าง</MainText>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Text>{`ชื่อตัวอย่าง : ${booking.nameSample}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`Grade : ${booking.nameGrade}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`LotNumber : ${booking.lotNumber}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`ต้นทาง : ${booking.fromPlaceName}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`ปลายทาง : ${booking.toPlaceName}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่เริ่ม : ${dayjs(booking.date).format("DD MMMM YYYY")}`}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`เวลา : ${booking.startTime} น.`}</Text>
            </Grid>
            <Grid item xs={12}>
              <Text>
                {`รายละเอียด : ${
                  booking.detail != null && booking.detail != ""
                    ? booking.detail
                    : ""
                }`}
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "grid", alignItems: "center" }}
            >
              <Text style={{ marginRight: "5px" }}>{`ไฟล์เพิ่มเติม : `}</Text>
              {booking.urlFiles != undefined && (
                <div
                  key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                >
                  <Stack direction="column" spacing={1}>
                    {booking.urlFiles != null && (
                      <>
                        <Stack direction="row" spacing={1}>
                          {booking.urlFiles.map((value, index) => (
                            <>
                              {checkIsPicture(index) === true && (
                                <a
                                  href={value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    height: "20%",
                                    width: "20%",
                                    margin: "5px",
                                  }}
                                >
                                  <img
                                    src={`${value}`}
                                    alt={`${takeAtl(index)}`}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      margin: "5px",
                                    }}
                                  />
                                </a>
                              )}
                            </>
                          ))}
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          {booking.urlFiles.map((value, index) => (
                            <>
                              {checkIsPicture(index) === false && (
                                <Chip
                                  key={`file_${index}`}
                                  label={`${takeAtl(index)}`}
                                  color="primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  component="a"
                                  href={value}
                                  clickable
                                />
                              )}
                            </>
                          ))}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </div>
              )}
              {booking.urlFiles === undefined ||
                booking.urlFiles === null ||
                (booking.urlFiles.length === 0 && (
                  <div
                    key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                  >
                    <TextFrom>ไม่มีไฟล์เพิ่มเติม</TextFrom>
                  </div>
                ))}
              {booking.isExp === 1 && (
                <div
                  key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                >
                  <>
                    <TextFrom>ขออภัย: ไฟล์หมดอายุ</TextFrom>
                  </>
                </div>
              )}
            </Grid>
          </GridContainer>
          <DividerStyled />
          {booking.idDriver ? (
            <>
              <GridContainer container spacing={1}>
                <Grid item xs={12} md={12}>
                  <MainText>รายละเอียดคนขับรถ</MainText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Text>{`คนขับรถ : ${booking.driverFullName}`}</Text>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Text>{`เบอร์โทรศัพท์คนขับรถ : ${booking.driverTelephone}`}</Text>
                </Grid>
              </GridContainer>
            </>
          ) : (
            <>
              <GridContainer container spacing={1}>
                <Grid item xs={12} md={12}>
                  <MainText>รายละเอียดคนขับรถ</MainText>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`คนขับรถ : `}</Text>
                  <Text style={{ color: "#d42121" }}>{` กำลังหาคนขับรถ`}</Text>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                  <Text>{`เบอร์โทรศัพท์คนขับรถ : `}</Text>
                  <Text style={{ color: "#d42121" }}>{` กำลังหาคนขับรถ`}</Text>
                </Grid>
              </GridContainer>
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailDeliverySampleShuttleCar;
