import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Container from "@mui/material/Container";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

// import DeliverySampleshuttleService from "../../../../services/deliverySampleshuttle.service"
import { getDeliverySampleshuttleByIdUser } from "../../../../actions/deliverySampleshuttle";
import { getDeliverySampleShuttleByFilter } from "../../../../actions/deliverySampleshuttle";
import { getEmployeesByCompany } from "../../../../actions/employee";
import SiteService from "../../../../services/site.service";
import employeeService from "../../../../services/employee.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import CardSampleRequest from "./NewSample/CardSampleRequest";

import { styled } from "@mui/styles";

import {
  Button,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Paper,
  InputAdornment,
} from "@mui/material";
import ButtonBlue from "../../shared/buttonBlue";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UniconLoading from "../../assets/loading.gif";
import { useEffect } from "react";
import CardSampleDetail from "./NewSample/CardDetailSample";
import NavButton from "../../shared/NavButton/NavButtonForm";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Grid: {
    width: "100%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
  CreateButton: {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "10px",
    margin: "3px",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "170px",
    },
  },
  CreateButtonSearch: {
    height: "40px",
    width: "40px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "5px",
    margin: "3px",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  IconImage: {
    weight: "26px",
    height: "26px",
    marginRight: "3px",
  },
}));

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const DivCoverReturn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // marginTop: "10px",
  width: "100%",

  // paddingLeft: "20px",
  // paddingRight: "20px",
  // paddingInline: "8vw",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  "& .ButtonTypeNotClicked": {
    backgroundColor: "#B2BABB ",
    color: "white",
    fontWeight: "bold",
    ["&:hover"]: {
      borderBottom: "0px",
      backgroundColor: "#D5DBDB",
    },
  },
}));

const Title = styled(Typography)({
  textAlign: "center",
  fontSize: "30px",
  marginBottom: "20px",
});

const ButtonType = styled(Button)({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "16px",
  borderRadius: "4px 4px 0 0",
  zIndex: "10",
  backgroundColor: "#FBFCFC",
  border: "1px solid #EEEEEE",
  borderBottom: "0",
  marginBottom: "-2px",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: "#FBFCFC",
  },
});

const NavDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const NavTopButton = styled("div")(({ theme }) => ({
  height: "60px",
  // marginTop: "20px",
  "& .CurrentTable": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));
//

const AllCards = styled("div")({
  height: "calc(100% - 153px)",
  // padding: "20px",
  marginBottom: "10px",
  paddingBottom: "0",
  paddingTop: "20px",
  // border: "1px solid #EEEEEE",
  zIndex: "1",
  // backgroundColor: "#FBFCFC",
  marginTop: "20px",
  borderRadius: "15px",
});

const SpaceOfAllCards = styled("div")({
  height: "95%",
  padding: "5px 4px 0px 4px",
  overflowY: "auto",
});

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const Sampleshuttle = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    result,
    isFetching: isFetchingList,
    isError: errorFetchingSample,
  } = useSelector((state) => state.deliverySampleShuttleCar);
  const [deliverySampleStatus, setDeliverySampleStatus] = useState("all");
  const listBooking = result[deliverySampleStatus];

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: allEmployees } = useSelector((state) => state.employees);
  const [allSite, setAllSite] = useState([]);

  const [showHistory, setHistory] = useState(0);
  const [isStartDate, setStartDate] = useState(null);
  const [isEndDate, setEndDate] = useState(null);
  const [isStatus, setStatus] = useState("ทั้งหมด");
  const [isEditList, setIsEditList] = useState(false);
  const [isNewList, setIsNewList] = useState(true);

  const [listBookingNotSuccess, setListBookingNotSuccess] = useState([]);
  const [listBookingIsSuccess, setListBookingIsSuccess] = useState([]);

  const onClickCardHandler = (idBooking) => {
    window.scroll(0, 0);
    props.history.push(`/sampleshuttle/detail_booking`, {
      idBooking: idBooking,
    });
  };

  const onClickNewList = () => {
    setIsEditList(false);
    setIsNewList(true);
    props.history.push({
      pathname: `/sampleshuttle/NewSample/formDetailFlight`,
      state: {
        isEditList: isEditList,
        isNewList: isNewList,
      },
    });
  };

  const onClickGoBack = () => {
    props.history.push("/carbooking");
  };

  const onClickHistory = async () => {
    const data = await dispatch(
      getDeliverySampleShuttleByFilter({
        nameSample: "",
        fromSite: "ทั้งหมด",
        toSite: "ทั้งหมด",
        status: "ได้รับสินค้าเรียบร้อย",
        startdate: null,
        enddate: null,
        idUser: currentUser.idUser,
      })
    );
    setHistory(1);
  };
  const onClickStatus = async () => {
    const data = await dispatch(
      getDeliverySampleShuttleByFilter({
        nameSample: "",
        fromSite: "ทั้งหมด",
        toSite: "ทั้งหมด",
        status: "ทั้งหมด",
        startdate: null,
        enddate: null,
        idUser: currentUser.idUser,
      })
    );
    setHistory(0);
  };

  const onClickSearch = async () => {
    setListBookingIsSuccess([]);
    setListBookingNotSuccess([]);
    const startDate =
      isStartDate === null
        ? null
        : isStartDate.$d.toLocaleString("af-ZA").slice(0, 10);
    const endDate =
      isEndDate === null
        ? null
        : isEndDate.$d.toLocaleString("af-ZA").slice(0, 10);

    const data = await dispatch(
      getDeliverySampleShuttleByFilter({
        nameSample: getValues("nameSample"),
        fromSite: getValues("fromSite"),
        toSite: getValues("toSite"),
        status: getValues("status"),
        startdate: startDate,
        enddate: endDate,
        idUser: currentUser.idUser,
      })
    );
  };

  useEffect(async () => {
    onClickSearch();

    await SiteService.getAllSite().then((res) => {
      if (res.data) {
        setAllSite(res.data);
      }
    });
    await employeeService.getEmployeesById(currentUser.idUser).then((res) => {
      if (res.data) {
        console.log(res.data);
        dispatch(getEmployeesByCompany(res.data[0].idCompany));
      }
    });
  }, []);

  useEffect(async () => {
    if (!isFetchingList && listBooking) {
      //   console.log(2222);

      //   const listA = [];
      //   const listB = [];
      //   data.map((booking) => {
      //     if (booking.status != "ได้รับสินค้าเรียบร้อย") {
      //       listA.push(booking);
      //     } else {
      //       listB.push(booking);
      //     }
      //   });

      //   setListBookingNotSuccess(listA);
      //   setListBookingIsSuccess(listB);
      // } else {
      console.log(3333);
      const listA = [];
      const listB = [];
      listBooking.map((booking) => {
        if (booking.status != "ได้รับสินค้าเรียบร้อย") {
          listA.push(booking);
        } else {
          listB.push(booking);
        }
      });

      setListBookingNotSuccess(listA);
      setListBookingIsSuccess(listB);
    }
  }, [isFetchingList, props.history]);
  return (
    <Container maxWidth="xl">
      <Root>
        <DivCoverReturn className="page">
          {/* <Title>รายการจองรถรับส่งตัวอย่าง</Title> */}
          <Grid
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
              marginBottom: "15px",
              [theme.breakpoints.down("sm")]: {
                display: "grid",
              },
              [theme.breakpoints.down("md")]: {
                display: "grid",
              },
            })}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid>
                <BackButton disableRipple onClick={onClickGoBack}>
                  <ArrowBackIcon
                    className="Icon"
                    style={{ color: "#99A8B2" }}
                  />
                </BackButton>
              </Grid>
              <Title>รายการจองรถรับส่งตัวอย่างการทดลอง</Title>
            </Grid>

            <NavTopButton>
              {showHistory === 0 && (
                <ButtonBlue
                  className={`${classes.CreateButton}`}
                  onClick={onClickHistory}
                >
                  <img
                    className={`${classes.IconImage}`}
                    src={"/assets/Image/Icon/clock_white.png"}
                    alt="Logo"
                  />
                  ประวัติการทำรายการ
                </ButtonBlue>
              )}
              {showHistory === 1 && (
                <ButtonBlue
                  className={`${classes.CreateButton}`}
                  onClick={onClickStatus}
                >
                  <img
                    className={`${classes.IconImage}`}
                    src={"/assets/Image/Icon/list.png"}
                    alt="Logo"
                  />
                  สถานะรายการ
                </ButtonBlue>
              )}

              <Button
                className={`${classes.CreateButton}`}
                onClick={onClickNewList}
                isEditList={isEditList}
                isNewList={isNewList}
              >
                <img
                  className={`${classes.IconImage}`}
                  src={"/assets/Image/Icon/form.png"}
                  alt="Logo"
                />
                เพิ่มรายการจองรถ
              </Button>
            </NavTopButton>
          </Grid>
          <Grid
            container
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              // padding: "25px 30px 25px 30px",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {
                // padding: "25px 30px 25px 15px",
              },
              [theme.breakpoints.down("md")]: {
                // padding: "25px 30px 25px 15px",
              },
            })}
          >
            <Grid
              container
              spacing={2}
              style={{
                marginTop: "25px",
                marginLeft: "15px",
                marginRight: "35px",
              }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="search"
                  type="search"
                  fullWidth
                  label="ค้นหาชื่อตัวอย่างการทดลอง"
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                      background: "rgb(254, 254, 254)",
                    },
                  }}
                  inputProps={{ ...register("nameSample") }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                  <Controller
                    name="startdate"
                    inputProps={{ ...register("startdate") }}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        label="วันที่เริ่ม"
                        inputFormat="DD MMMM YYYY"
                        value={isStartDate}
                        onChange={(date) => setStartDate(date)}
                        clearable
                        componentsProps={{
                          actionBar: {
                            actions: ["clear", "cancel", "accept"],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                  <Controller
                    name="enddate"
                    inputProps={{ ...register("enddate") }}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        minDate={isStartDate}
                        value={isEndDate}
                        onChange={(date) => setEndDate(date)}
                        label="วันที่สิ้นสุด"
                        inputFormat="DD MMMM YYYY"
                        clearable
                        componentsProps={{
                          actionBar: {
                            actions: ["clear", "cancel", "accept"],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {showHistory === 0 && (
                <Grid item xs={12} md={2.5}>
                  <TextField
                    inputProps={{ ...register("status") }}
                    value={isStatus}
                    onChange={(e) => setStatus(e.target.value)}
                    label="สถานะ"
                    select
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                    fullWidth
                    defaultValue={"ทั้งหมด"}
                  >
                    <MenuItem value={"ทั้งหมด"}>ทั้งหมด</MenuItem>
                    <MenuItem value={"รอรับสินค้า"}>รอรับสินค้า</MenuItem>
                    <MenuItem value={"รับสินค้าเรียบร้อย"}>
                      รับสินค้าเรียบร้อย
                    </MenuItem>
                    <MenuItem value={"ส่งสินค้าเรียบร้อย"}>
                      ส่งสินค้าเรียบร้อย
                    </MenuItem>
                    {/* <MenuItem value={"ได้รับสินค้าเรียบร้อย"}>
                      ได้รับสินค้าเรียบร้อย
                    </MenuItem> */}
                  </TextField>
                </Grid>
              )}
              {showHistory === 1 && (
                <Grid item xs={12} md={2.5}>
                  <TextField
                    inputProps={{ ...register("status") }}
                    value={isStatus}
                    onChange={(e) => setStatus(e.target.value)}
                    label="สถานะ"
                    select
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                    fullWidth
                    defaultValue={"ทั้งหมด"}
                  >
                    <MenuItem value={"ทั้งหมด"}>ทั้งหมด</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12} md={4.75}>
                <TextField
                  inputProps={{ ...register("fromSite") }}
                  label="จุดรับ"
                  defaultValue={"ทั้งหมด"}
                  select
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  fullWidth
                >
                  <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                  {allSite.map((value, index) => (
                    <MenuItem key={index} value={value.idScgSite}>
                      {value.noSite !== null &&
                        `Site${value.noSite}: ${value.nameSite}`}
                      {value.noSite === null && `${value.nameSite}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4.75}>
                <TextField
                  inputProps={{ ...register("toSite") }}
                  label="จุดส่ง"
                  defaultValue={"ทั้งหมด"}
                  select
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  fullWidth
                >
                  <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                  {allSite.map((value, index) => (
                    <MenuItem key={index} value={value.idScgSite}>
                      {value.noSite !== null &&
                        `Site${value.noSite} :${value.nameSite}`}
                      {value.noSite === null && `${value.nameSite}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <ButtonBlue
                  variant="contained"
                  onClick={onClickSearch}
                  style={{
                    maxWidth: "200px",
                    maxHeight: "35px",
                    borderRadius: "15px",
                    marginRight: "25px",
                  }}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </ButtonBlue>
              </Stack>
            </Grid>
          </Grid>
          <AllCards>
            <SpaceOfAllCards>
              {isFetchingList ? (
                <Paper
                  sx={{
                    overflow: "hidden",
                    border: "1px solid #EEEEEE",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "20px",
                    padding: "25px 30px 25px 30px",
                    borderRadius: "15px",
                    textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                  }}
                >
                  <img
                    src={UniconLoading}
                    alt="Loading..."
                    style={{ width: "300px" }}
                  />
                  {/* ปรับขนาดของภาพตามที่ต้องการ */}
                </Paper>
              ) : (
                <>
                  {" "}
                  {errorFetchingSample ? (
                    <Paper
                      sx={{
                        // width: "100%",
                        overflow: "hidden",
                        border: "1px solid #EEEEEE",
                        backgroundColor: "#FFFFFF",

                        marginBottom: "20px",
                        // padding: "0px 25px 25px 0px",
                        padding: "25px 30px 25px 30px",

                        borderRadius: "15px",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "center", fontSize: "24px" }}
                      >
                        ไม่มีรายการ
                      </Typography>
                    </Paper>
                  ) : (
                    <>
                      {showHistory === 0 &&
                        (listBookingNotSuccess &&
                        listBookingNotSuccess.length > 0 ? (
                          <CardSampleRequest
                            onClickCardHandler={onClickCardHandler}
                            listBooking={listBookingNotSuccess}
                            allSite={allSite}
                          />
                        ) : (
                          <Paper
                            sx={{
                              overflow: "hidden",
                              border: "1px solid #EEEEEE",
                              backgroundColor: "#FFFFFF",
                              marginBottom: "20px",
                              padding: "25px 30px",
                              borderRadius: "15px",
                            }}
                          >
                            <Typography
                              style={{ textAlign: "center", fontSize: "24px" }}
                            >
                              ไม่มีรายการ
                            </Typography>
                          </Paper>
                        ))}

                      {showHistory === 1 &&
                        (listBookingIsSuccess &&
                        listBookingIsSuccess.length > 0 ? (
                          <CardSampleRequest
                            onClickCardHandler={onClickCardHandler}
                            listBooking={listBookingIsSuccess}
                            allSite={allSite}
                          />
                        ) : (
                          <Paper
                            sx={{
                              overflow: "hidden",
                              border: "1px solid #EEEEEE",
                              backgroundColor: "#FFFFFF",
                              marginBottom: "20px",
                              padding: "25px 30px",
                              borderRadius: "15px",
                            }}
                          >
                            <Typography
                              style={{ textAlign: "center", fontSize: "24px" }}
                            >
                              ไม่มีรายการ
                            </Typography>
                          </Paper>
                        ))}                
                    </>
                  )}
                </>
              )}
            </SpaceOfAllCards>
          </AllCards>
        </DivCoverReturn>
      </Root>
    </Container>
  );
};

export default Sampleshuttle;
