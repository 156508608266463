import {
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
} from "../../actions/types";

const initialState = {
  result: {},
  isFetching: false,
  isError: false,
};

export default function deliverySampleShuttleCarBookingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING:
      return { ...state, isFetching: true, isError: false };

    case DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED:
      return { ...state, isFetching: false, isError: true };

    case DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS:
      if (!Array.isArray(payload)) {
        console.error("Invalid payload format. Expected an array:", payload);
        return state;
      }

      const groupedData = payload.reduce((acc, item) => {
        const { deliveryStatus } = item;
        if (!deliveryStatus) {
          acc["all"] = acc["all"] || [];
          acc["all"].push(item);
          return acc;
        }

        acc[deliveryStatus] = acc[deliveryStatus] || [];
        acc[deliveryStatus].push(item);
        return acc;
      }, {});

      return {
        ...state,
        result: {
          ...state.result,
          ...groupedData,
        },
        isFetching: false,
        isError: false,
      };

    default:
      return state;
  }
}
